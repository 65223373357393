import * as React from 'react';
import { Link } from 'react-router-dom';
import { Table, TableContainer, TableCell, TableRow, TableBody, Typography } from '@mui/material';
import { IClusterInfo } from "./Clusters";
import { useFetchApi } from '../utils/UseFetchApi';
import CenteredProgress from './CenteredProgress';
import { red, orange, grey, blue, green } from '@mui/material/colors';
import Flag from 'react-world-flags';
import { useParams } from 'react-router-dom';
import {styled} from'@mui/system';

// Link to cluster details page
export function ClusterLink(props: { cluster: IClusterInfo }) {
    return (
        <Link to={{ pathname: `/admin/clusters/${props.cluster.location}/${props.cluster.name}` }} style={{ textDecoration: 'none' }}>{props.cluster.name}</Link>
    );
}


const StyledDiv = styled('div')((props)=> ({
    backgroundColor: props.theme.palette.background.paper,
}))

const StyledDiv2 = styled('div')((props)=> ({
    padding: props.theme.spacing(1),
}))

const StyledTableRow = styled(TableRow)(()=> ({
    border: '0px'
}))

const StyledTableCell = styled(TableCell)(()=> ({
    border: '0px'
}))

interface IClusterRow {
    label: string;
    fill: number | string;
    style?: any;
}

export function clusterHasTrouble(cluster: IClusterInfo)
{
    const workingNodes = cluster.nodeStatistics.running + cluster.nodeStatistics.idle;
    if (workingNodes > 0)
    {
        return false;
    }
    const issueNodes = cluster.nodeStatistics.unusable + cluster.nodeStatistics.start_task_failed;
    return (issueNodes > 0);
}

function ClusterInfoDisplay(props : {cluster: IClusterInfo}) {

    function regionFlag(location: string)
    {
        if (location === "EastUs")
        {
            return (<Flag code="US" />)
        }
        if (location === "NorthEurope")
        {
            return (<Flag code="EU" />)
        }
        if (location === "SoutheastAsia")
        {
            return (<Flag code="SG" />)
        }
        if (location === "JapanEast")
        {
            return (<Flag code="JP" />)
        }
        if (location === "AustraliaEast")
        {
            return (<Flag code="AU" />)
        }
        if (location === "UKSouth")
        {
            return (<Flag code="GB" />)
        }
        if (location === "CentralIndia")
        {
            return (<Flag code="IN" />)
        }
        return (<Flag code="FR" />);
    }

    function showWarning(cluster: IClusterInfo)
    {
        if (clusterHasTrouble(cluster))
            return (<Typography variant="h5" style={{ color: orange[700] }}>Cluster is having trouble!</Typography>)
        return (<Typography variant="h5" style={{ color: blue[400] }}>Cluster is fine</Typography>)
    }

    function createRow(clusterRow: IClusterRow)
    {
        return (
            <StyledTableRow  >
                <StyledTableCell   style={clusterRow.style ? clusterRow.style : {}}>
                    <strong>{clusterRow.label}</strong>
                </StyledTableCell>
                <StyledTableCell   style={clusterRow.style ? clusterRow.style : {}}>
                    {clusterRow.fill}
                </StyledTableCell>
            </StyledTableRow>
        );
    }

    function clusterStatus(cluster: IClusterInfo)
    {
        const clusterRows: IClusterRow[] = [{label: "Active jobs:", fill: cluster.jobStatistics.active},
            {label: "Completed jobs:", fill: cluster.jobStatistics.completed},
            {label: "Dedicated nodes:", fill: cluster.dedicatedNodes},
            {label: "Low-priority nodes:", fill: cluster.lowPriorityNodes},
            {label: "State:", fill: cluster.state}];

        return (
            <Table   size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <StyledTableRow  >
                        <Typography variant="h6">
                            Status:
                        </Typography>
                    </StyledTableRow>
                    {clusterRows.map((clusterRow) => createRow(clusterRow))}
                </TableBody>
            </Table>
        );
    }

    function getColor(i: number, type: string)
    {
        if (i === 0)
        {
            return grey[500];;
        }
        switch (type)
        {
            case "start_task_failed":
                return orange[500];
            case "unusable":
                return red[900];
            case "running":
                return green[800];
            case "idle":
                return grey[800]
            default:
                return blue[600];
        }
    }

    function nodeStatus(cluster: IClusterInfo)
    {
        const nodeRows: IClusterRow[] = [{label: "Idle:", fill: cluster.nodeStatistics.idle, style: {color: getColor(cluster.nodeStatistics.idle, "idle")}},
            {label: "Running:", fill: cluster.nodeStatistics.running, style: {color: getColor(cluster.nodeStatistics.running, "running")}},
            {label: "Starting:", fill: cluster.nodeStatistics.starting, style: {color: getColor(cluster.nodeStatistics.starting, "")}},
            {label: "Waiting for start task:", fill: cluster.nodeStatistics.start_task, style: {color: getColor(cluster.nodeStatistics.start_task, "")}},
            {label: "Start task failed:", fill: cluster.nodeStatistics.start_task_failed, style: {color: getColor(cluster.nodeStatistics.start_task_failed, "start_task_failed")}},
            {label: "Leaving:", fill: cluster.nodeStatistics.leaving, style: {color: getColor(cluster.nodeStatistics.leaving, "")}},
            {label: "Unusable", fill: cluster.nodeStatistics.unusable, style: {color: getColor(cluster.nodeStatistics.unusable, "unusable")}},
            {label: "Preempted:", fill: cluster.nodeStatistics.preempted, style: {color: getColor(cluster.nodeStatistics.preempted, "")}},
            {label: "Rebooting:", fill: cluster.nodeStatistics.rebooting, style: {color: getColor(cluster.nodeStatistics.rebooting, "")}},
            {label: "Other:", fill: cluster.nodeStatistics.other, style: {color: getColor(cluster.nodeStatistics.other, "")}}];


        return (
            <Table   size="small" style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    <StyledTableRow>
                        <Typography variant="h6">
                            Nodes:
                        </Typography>
                    </StyledTableRow>
                    {nodeRows.map((statusRow) => createRow(statusRow))}
                </TableBody>
            </Table>
        );
    }

    return (
        <StyledDiv2>
            <TableContainer >
                <Table   size="small" style={{ width: "auto", tableLayout: "auto" }}>
                    <StyledTableCell>
                        <Typography style={{ width: 50 }}>
                            {regionFlag(props.cluster.location)}
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                        <Typography variant="h4">
                            <b>{props.cluster.name}</b> - {props.cluster.location}
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                        {showWarning(props.cluster)}
                    </StyledTableCell>
                </Table>
                <Table   size="small">
                    <StyledTableCell>
                        {clusterStatus(props.cluster)}
                    </StyledTableCell>
                    <StyledTableCell>
                        {nodeStatus(props.cluster)}
                    </StyledTableCell>
                </Table>
            </TableContainer>
        </StyledDiv2>
    )
}

export default function ClusterDetails(props: any) {

    const location = useParams().location as unknown as string;
    const name = useParams().name as unknown as string;

    const clusterFetch = useFetchApi<IClusterInfo>(window.location.origin + `/api/v2/clusters/locations/${location}/${name}`);

    React.useEffect(() => {
        clusterFetch.run();
    }, []);

    if (clusterFetch.isFetching)
        return (<CenteredProgress />);

    if (clusterFetch.error)
        return (<div>{`${clusterFetch.error}`}</div>);

    if (!clusterFetch.data)
        return (<div>{`Cluster ${name}-${location} not found`}</div>);

    return (
        <StyledDiv >
             <StyledDiv2 >
                  <ClusterInfoDisplay cluster={clusterFetch.data} />
             </StyledDiv2>
        </StyledDiv>
    );
}
